<template>
  <div class="px-2">
    <filter-slot
      :filter="[]"
      :total-rows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :start-page="FilterSlot.startPage"
      :to-page="FilterSlot.toPage"
      :filter-principal="FilterSlot.filterPrincipal"
      @reload="$refs['typesNcr'].refresh()"
    >
      <template #buttons>
        <div
          class="ml-2"
          v-if="tabSelected === 'manual' || tabSelected === 'services'"
        >
          <b-button
            variant="primary"
            @click="openCreateModal"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            :disabled="!hasPermission"
          >
            <tabler-icon icon="PlusIcon" />
            Create New
          </b-button>
        </div>
        <div
          class="ml-2"
          v-if="tabSelected === 'manual' || tabSelected === 'automatic'"
        >
          <b-button
            variant="primary"
            @click="openCreateModalRates()"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            :disabled="!hasPermission"
          >
            <tabler-icon icon="EditIcon" />
            Rates
          </b-button>
        </div>
        <div class="ml-2" v-if="tabSelected === 'services'">
          <b-button
            variant="primary"
            class="text-capitalize d-flex align-items-center"
            style="gap: 5px"
            @click="openZeroPaymentModal()"
          >
            <tabler-icon icon="EditIcon" />
            Zero Payment
          </b-button>
        </div>
      </template>
      <b-table
        ref="typesNcr"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="myFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
        :class="$route.name"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.status == 'ACTIVE' ? 'light-success' : 'light-warning'
            "
            @click="changeStatus(data.item)"
            class="clickable"
          >
            {{ data.item.status }}
            <feather-icon
              icon="RepeatIcon"
              :class="
                data.item.status == 'ACTIVE' ? 'text-success' : 'text-warning'
              "
              size="22"
              v-b-tooltip.hover.top="
                data.item.status == 'ACTIVE' ? 'Disable' : 'Enable'
              "
            />
          </b-badge>
        </template>

        <template #cell(keywords)="data">
          <b-badge
            variant="light-secondary"
            v-for="({ id, keyword }, index) in data.item.keywords"
            :key="id"
            style="margin-bottom: 3px; margin-right: 3px"
          >
            <div
              class="d-flex flex-wrap align-items-center justify-content-center"
              style="gap: 5px"
            >
              <span>Key {{ index + 1 }}: </span>
              <b-badge
                v-for="(word, idx) in JSON.parse(keyword)"
                :key="idx"
                variant="primary"
              >
                {{ word }}
              </b-badge>
            </div>
          </b-badge>
        </template>

        <template #cell(only_file)="data">
          <template>
            <feather-icon
              v-if="data.item.only_file === 1"
              icon="CheckIcon"
              size="16"
              class="text-success"
            />
            <feather-icon v-else icon="XIcon" size="16" class="text-danger" />
          </template>
        </template>

        <template #cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <template v-if="tabSelected === 'keywords'">
              <feather-icon
                icon="EditIcon"
                size="16"
                class="text-info clickable"
                v-b-tooltip.hover.top="'Edit'"
                @click="openKeyword(data.item)"
              />
            </template>

            <template v-else>
              <b-button
                v-if="tabSelected === 'manual' || tabSelected === 'services'"
                class="p-0"
                variant="outline"
                v-b-tooltip.hover.top="'Tracking'"
                @click="openTrackingModal(data.item)"
              >
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="text-primary clickable"
                />
              </b-button>
              <b-button
                class="p-0 pl-1"
                variant="outline"
                v-b-tooltip.hover.top="'Edit'"
                @click="editModal(data.item)"
                :disabled="tabSelected === 'automatic'"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  :class="
                    tabSelected === 'manual' || tabSelected === 'services'
                      ? 'text-info'
                      : 'text-grey'
                  "
                />
              </b-button>
              <b-button
                v-if="tabSelected === 'manual' || tabSelected === 'services'"
                class="p-0 pl-1"
                variant="outline"
                v-b-tooltip.hover.top="'Delete'"
                :disabled="
                  (data.item.id === 1 || data.item.id === 2) &&
                  tabSelected === 'services'
                "
                @click="deleteItem(data.item.id)"
              >
                <feather-icon icon="TrashIcon" size="16" class="text-danger" />
              </b-button>
            </template>
          </div>
        </template>

        <template #cell(reasons)="data">
          <div class="d-flex align-items-start">
            <ul class="flex-grow-1">
              <template v-for="(item, index) in data.item.reasons">
                <li :key="index" v-if="index < 3">
                  {{ item.value }}
                </li>
                <b-badge
                  variant="light-primary"
                  class="clickable"
                  :key="index"
                  v-else-if="index == 3"
                  @click="openList(data.item, 1)"
                >
                  + {{ data.item.reasons.length - 3 }} more
                </b-badge>
              </template>
            </ul>
            <b-button
              class="flex-shrink-0"
              variant="primary"
              size="sm"
              @click.prevent="openModalClassificationNcr(data.item, 'Reasons')"
            >
              Edit reasons
            </b-button>
          </div>
        </template>
        <template #cell(instructions)="data">
          <div class="d-flex align-items-start">
            <ul class="flex-grow-1">
              <template v-for="(item, index) in data.item.instructions">
                <li :key="index" v-if="index < 3">
                  {{ item.value | firstLetterCapitalize }}
                </li>
                <b-badge
                  variant="light-primary"
                  class="clickable"
                  :key="index"
                  v-else-if="index == 3"
                  @click="openList(data.item, 2)"
                >
                  + {{ data.item.instructions.length - 3 }} more
                </b-badge>
              </template>
            </ul>

            <b-button
              class="flex-shrink-0"
              variant="primary"
              size="sm"
              @click.prevent="
                openModalClassificationNcr(data.item, 'Instrucciones')
              "
            >
              Edit instructions
            </b-button>
          </div>
        </template>
      </b-table>
    </filter-slot>

    <CreateModal
      v-if="showCreateModal"
      @close="closeCreateModal"
      :mode="type"
      @refresh="refreshTable()"
      :itemTypeNrc="itemTypeNrc"
      :tabSelected="tabSelected"
    />
    <TrackingModal
      v-if="showTrackingModal"
      @close="closeTrackingModal"
      :item="item"
      :tabSelected="tabSelected"
    />
    <AddKeywords
      @close="showKeywords = false"
      v-if="showKeywords"
      :item="itemTypeNrc"
      @refresh="refreshTable()"
    />
    <EditClassificationNcr
      v-if="classificationNcr"
      :classificationNcr="classificationNcr"
      :typeClassification="typeClassification"
      @close="closeModalClassificationNcr"
    />
    <ListInfo
      v-if="showList"
      :data="dataList"
      @close="showList = false"
      :type="type"
    />

    <rates-modal v-if="showModalRates" @close="showModalRates = false" />
    <zero-payment-module
      v-if="showModalZeroPayment"
      @close="showModalZeroPayment = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Fields from "@/views/specialists/sub-modules/financial-approval/views/settings/data/fields.js";
import CreateModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/CreateModal.vue";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import TrackingModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/TrackingModal.vue";
import PricesNcrType from "@/views/specialists/sub-modules/financial-approval/views/settings/views/components/PricesNcrType.vue";
import AddKeywords from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/AddKeywords.vue";
import EditClassificationNcr from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/EditClassificationNcr.vue";
import ListInfo from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/ListInfo.vue";
import RatesModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/RatesModal.vue";
import ZeroPaymentModule from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/ZeroPaymentModal.vue";

export default {
  components: {
    CreateModal,
    TrackingModal,
    PricesNcrType,
    AddKeywords,
    EditClassificationNcr,
    ListInfo,
    RatesModal,
    ZeroPaymentModule,
  },

  data() {
    return {
      classificationNcr: null,
      typeClassification: "",
      showCreateModal: false,
      Fields,
      isBusy: false,
      type: null,
      typencrData: [],
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },

      showTrackingModal: false,
      item: null,
      itemType: "",
      itemTypeNrc: {},
      showKeywords: false,
      showList: false,
      showModalRates: false,
      showModalZeroPayment: false,
      dataList: [],
    };
  },

  props: {
    tabSelected: {
      type: String,
    },
  },
  filters: {
    firstLetterCapitalize(valor) {
      if (!valor) return;
      return valor.charAt(0).toUpperCase() + valor.slice(1).toLowerCase();
    },
  },
  computed: {
    ...mapGetters({
      theme: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    hasPermission() {
      return this.isCeo || this.isChief || this.isSupervisor;
    },
    myFields() {
      return this.Fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.myFieldsFiltered();
  },
  methods: {
    openList(data, type) {
      this.dataList = data;
      this.type = type;
      this.showList = true;
    },
    filterColumns(key, bool) {
      let field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    myFieldsFiltered() {
      this.filterColumns("name", this.$route.name !== "motives");
      this.filterColumns("price", this.$route.name === "services");
      this.filterColumns("expenses", this.$route.name === "manual");
      this.filterColumns(
        "created_by",
        this.$route.name === "manual" ||
          this.$route.name === "motives" ||
          this.$route.name === "services"
      );
      this.filterColumns(
        "status",
        this.$route.name === "automatic" || this.$route.name === "services"
      );
      this.filterColumns("keywords", this.$route.name === "keywords");
      this.filterColumns("description", this.$route.name === "motives");
      this.filterColumns("only_file", this.$route.name === "manual");
      this.filterColumns("obtainer_cost", this.$route.name === "manual");
      this.filterColumns("acquisition_cost", this.$route.name === "manual");
      this.filterColumns("reasons", this.$route.name === "classification-ncr");
      this.filterColumns(
        "instructions",
        this.$route.name === "classification-ncr"
      );
      this.filterColumns(
        "created_at",
        this.$route.name !== "classification-ncr"
      );
      this.filterColumns("action", this.$route.name !== "classification-ncr");
    },
    openCreateModal() {
      this.type = "Create";
      this.showCreateModal = true;
    },

    editModal(data) {
      this.type = "Update";
      this.showCreateModal = true;
      this.itemTypeNrc = data;
    },

    closeCreateModal() {
      this.showCreateModal = false;
      this.itemTypeNrc = {};
      this.type = null;
    },

    openTrackingModal(item) {
      this.item = item;
      this.showTrackingModal = true;
    },

    closeTrackingModal() {
      this.showTrackingModal = false;
    },
    closeModalClassificationNcr(reload) {
      this.classificationNcr = null;
      this.typeClassification = null;
      if (reload) {
        this.$refs.typesNcr.refresh();
      }
    },
    refreshTable() {
      this.$refs.typesNcr.refresh();
    },

    getProviderServicesByTab() {
      const map = {
        manual: "getAllManualNcrTypes",
        automatic: "getAllAutotmaticNcrTypes",
        motive: "getNcrFailedMotives",
        keywords: "getClassificationNcr",
        "classification-ncr": "getClassificationNcrReasonsInstructions",
        services: "searchServices",
      };
      return map[this.tabSelected] ?? "NA";
    },

    async myProvider() {
      const { model } = this.FilterSlot.filterPrincipal;
      const { currentPage, perPage } = this.FilterSlot.paginate;

      const formdata = {
        search: model,
        page: currentPage,
        perPage: perPage,
        type: this.tabSelected == "keywords" ? 1 : 2,
      };
      try {
        let service = this.getProviderServicesByTab();
        let data = await SettingsServiceSp[service](formdata);
        const paginationInfo = {
          startPage: data.from,
          currentPage: data.current_page,
          perPage: data.per_page,
          totalRows: data.total,
          toPage: data.to,
        };
        this.FilterSlot = { ...this.FilterSlot, ...paginationInfo };
        this.typencrData = data.data;

        return this.typencrData;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async deleteItem(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();

          let methodService =
            this.tabSelected == "manual"
              ? "deleteManualNcrType"
              : "deleteSpecialistServices";

          const data = await SettingsServiceSp[methodService]({
            id: id,
            user_id: this.currentUser.user_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$refs["typesNcr"].refresh();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async changeStatus(data) {
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the status?`,
        null
      );

      if (confirm.isConfirmed) {
        let newStatus = data.status == "ACTIVE" ? 2 : 1;
        const formdata = {
          id: data.id,
          status: newStatus,
          userId: this.currentUser.user_id,
        };
        try {
          let methodService;
          switch (this.tabSelected) {
            case "automatic":
              methodService = "updateNcrStatusAutomatic";
              break;

            case "services":
              methodService = "updateService";
              break;
          }

          let { data } = await SettingsServiceSp[methodService](formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
            this.refreshTable();
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.isActive = true;
          this.showErrorSwal(error);
        }
      }
    },

    openKeyword(item) {
      this.itemTypeNrc = item;
      this.showKeywords = true;
    },
    openModalClassificationNcr(item, type) {
      this.classificationNcr = item;
      this.typeClassification = type;
    },

    openCreateModalRates() {
      this.showModalRates = true;
    },

    openZeroPaymentModal() {
      this.showModalZeroPayment = true;
    },
  },
};
</script>
<style>
div.classification-ncr .table td {
  vertical-align: top !important;
}
</style>
