<template>
  <b-modal
    v-model="showModal"
    modal-class="modal-primary"
    title-class="h4 text-white font-weight-bolder"
    title="ZERO PAYMENT"
    size="md"
    @hidden="closeModal()"
    hide-footer
    no-close-on-backdrop
    centered
  >
    <div>
      <b-card
        :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
        title="Available modules"
        sub-title="Select a module to give access to zero payment"
      >
        <b-overlay
         
          variant="info"
          :opacity="0.5"
          spinner-variant="info"
          spinner-type="grow"
          spinner-small
          rounded="sm"
          style="max-height: 500px"
        >
          <b-list-group class="scrollable-list">
            <b-list-group-item
              v-for="(module, key) in data"
              :key="key"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <h4>{{ module.name }}</h4>
              </div>
              <div class="d-flex align-items-center">
                <b-button
                  class="p-0 mr-2"
                  variant="outline"
                  v-b-tooltip.hover.top="'Tracking'"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="24"
                    class="text-primary clickable"
                    @click="openTrackingModal(module)"
                  />
                </b-button>
                <b-form-checkbox
                  v-model="module.status"
                  value="1"
                  unchecked-value="0"
                  switch
                  v-b-tooltip.hover.top="'Tracking'"
                  @change="updateStatusModule(module)"
                />
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-overlay>
      </b-card>

      <!--   <b-table
        ref="ZeroPaymentTable"
        :fields="fields"
        :items="data"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            :icon="
              data.item.status != 'INACTIVE' ? 'ThumbsDownIcon' : 'ThumbsUpIcon'
            "
            size="20"
            class="cursor-pointer"
            :class="
              data.item.status != 'INACTIVE' ? 'text-danger' : 'text-success'
            "
            v-b-tooltip.hover.top="
              data.item.status == 'INACTIVE'
                ? 'Activate Zero Payment'
                : 'Desactive Zero Payment'
            "
            @click="updateStatusModule(data.item)"
          />
        </template>
        <template #cell(status)="data">
          <b-badge
            class="w-100"
            pill
            :variant="data.item.status != 'INACTIVE' ? 'success' : 'danger'"
          >
            {{ data.item.status | myFontCapitalize }}
          </b-badge>
        </template>
        <template #cell(tracking)="data">
          <feather-icon
            v-b-tooltip.hover.top="'Tracking'"
            icon="ListIcon"
            size="20"
            class="cursor-pointer text-primary"
            @click="openTrackingModal(data.item)"
          />
        </template>
      </b-table> -->
      <TrackingModal
        v-if="showTrackingModal"
        @close="showTrackingModal = false"
        :item="item"
        :tabSelected="'zero payment'"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import TrackingModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/TrackingModal.vue";

export default {
  components: {
    TrackingModal,
  },
  data() {
    return {
      showModal: false,
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "MODULE",
          tdClass: "pt-1",
        },
        {
          key: "status",
          thClass: "text-center",
          tdClass: "pt-1",
        },
        {
          key: "tracking",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      data: [],
      showTrackingModal: false,
      item: null,
      moduleStatus: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.openModal();
    this.getDataZeroPaymentsModule();
  },
  methods: {
    async getDataZeroPaymentsModule() {
      try {
        this.isBusy = true;
        const { data } = await SettingsServiceSp.getZeroPaymentModules();
        this.data = data.data;
        this.isBusy = false;

        return this.data;
      } catch (error) {
        this.showToast(
          "error",
          "top-right",
          "Oops!",
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },

    async updateStatusModule(item) {
      try {
        const confirm = await this.showConfirmSwal("Are you sure?", null);
        if (!confirm.value) {
          item.status == 0 ? (item.status = 1) : (item.status = 0);
          return;
        }
        //console.log(item.status);
        const formdata = {
          id: item.id,
          status: item.status,
          updatedBy: this.currentUser.user_id,
        };
        let data = await SettingsServiceSp.updateZeroPaymentModules(formdata);
        if (data.success) {
          this.getDataZeroPaymentsModule();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );

        } else {
          this.showToast(
            "danger",
            "top-right",
            "Failed!",
            "XIcon",
            "Failed operation"
          );
        }
      } catch (error) {
        this.showToast(
          "error",
          "top-right",
          "Oops!",
          "XIcon",
          "Something went wrong"
        );
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$emit("close");
    },
    openTrackingModal(item) {
      this.item = item;
      this.showTrackingModal = true;
    },
  },
};
</script>



