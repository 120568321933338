import { render, staticRenderFns } from "./SettingsTable.vue?vue&type=template&id=78d4564b"
import script from "./SettingsTable.vue?vue&type=script&lang=js"
export * from "./SettingsTable.vue?vue&type=script&lang=js"
import style0 from "./SettingsTable.vue?vue&type=style&index=0&id=78d4564b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports