import moment from "moment";
export default [
  {
    key: "name",
    label: "Name",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "expenses",
    label: "Total Cost",
    tdClass: "pt-1 text-center text-success",
    thClass: "text-center",
    formatter: (value) => `$ ${value}`,
    visible: false,
  },
  {
    key: "obtainer_cost",
    label: "Obtained Cost",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    formatter: (value) => `$ ${value}`,
    visible: false,
  },
  {
    key: "acquisition_cost",
    label: "Acquisition Cost ",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    formatter: (value) => `$ ${value}`,
    visible: false,
  },
  {
    key: "price",
    label: "Price",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    formatter: (value) => `$ ${value}`,
    visible: false,
  },
  {
    key: "description",
    label: "Motive",
    tdClass: "pt-1",
    visible: false,
  },
  {
    key: "status",
    label: "Status",
    tdClass: "pt-1 text-center",
    thClass: "text-center",
    visible: false,
  },
  {
    key: "only_file",
    label: "Only File",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: false,
  },
  {
    key: "reasons",
    label: "Reasons",
    tdClass: "pt-1",
    thClass: "text-center",
    thStyle: {
      width: "40%",
    },
    visible: false,

  },
  {
    thClass: "text-center",
    key: "instructions",
    label: "Instructions",
    tdClass: "pt-1",
    thStyle: {
      width: "40%",
    },
    visible: false,
  },
  {
    key: "created_by",
    label: "CREATED BY",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: false,
  },
  {
    key: "created_at",
    label: "CREATED AT",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    formatter: (value) => {
      if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
      return "-";
    },
    visible: true,
  },
  {
    key: "keywords",
    label: "Keywords",
    tdClass: "pt-1",
    thStyle: {
      width: "50%",
    },
    visible: false,
  },
  {
    key: "action",
    label: "Actions",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
];
