<template>
  <b-modal
    v-model="showModalRates"
    size="sm"
    title-class="h3 text-white font-weight-bolder"
    :title="`RATES ${$route.matched[2].name.toUpperCase()}`"
    @hide="closeModal"
    centered
    hide-footer
  >
    <PricesNcrType
      :title="this.$route.matched[2].name.toUpperCase()"
      ref="pricesNcrComponent"
    ></PricesNcrType>
  </b-modal>
</template>

<script>
import PricesNcrType from "@/views/specialists/sub-modules/financial-approval/views/settings/views/components/PricesNcrType.vue";

export default {
  components: {
    PricesNcrType,
  },

  data() {
    return {
      total: true,
      transaction: "PAYMENT",
    };
  },
  created() {
    this.openModal();
  },

  methods: {
    openModal() {
      this.showModalRates = true;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>